import React from "react";
import Header from "../components/header/header";
import CountdownTimer from "../components/extras/countdown";
import HomeImage from '../images/headers/ouhome.png';    /* rename image when changed : see tip ## */
import Logo from '../images/MDM-Logo.png';
import '../App.css';
import '../style/home.css';

const Home = () => {
    return (
        <>
            <header>
                <span className="welcome">Join us February 28 - March 2, 2025 for the 7th annual</span>
                <Header text="MEDICAL DEVICE" text2="MAKE-A-THON" tag="mdm" backgroundImage={HomeImage} />
                {/*<CountdownTimer />*/}
            </header>

            {/*<body>*/}
            {/*<div className="about">*/}
            {/*    <div>*/}
            {/*        <h1> About Us </h1>*/}
            {/*        <p>*/}
            {/*            The Medical Device Make-A-Thon is a 48-hour competition where teams of students from diverse academic backgrounds*/}
            {/*            come together to conceptualize, design, and showcase a medical device solution to a real-world medical topic.*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            Participants will have the opportunity to showcase their creativity and*/}
            {/*            problem-solving skills while engaging in a fun, fast-paced environment.*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            Students from all over the country are invited to participate*/}
            {/*            in this fun-filled weekend of networking and innovation!*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*    <img src={Logo} alt={"MDM 2025 Logo"} loading="lazy"/>*/}
            {/*</div>*/}

            {/*<div className="more-info">*/}
            {/*    <div className="info-box" id="workshop">*/}
            {/*        <h4>*/}
            {/*            Workshop*/}
            {/*        </h4>*/}
            {/*        <ul>*/}
            {/*            <li><span>Participate in SolidWorks, Fusion 360, and 3D Printing workshops to bring your designs to life</span></li>*/}
            {/*            <li><span>Receive mentorship from industry professionals to refine your design</span></li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*    <div className="info-box" id="network">*/}
            {/*        <h4>*/}
            {/*            Network*/}
            {/*        </h4>*/}
            {/*        <ul>*/}
            {/*            <li><span>Engage with participants, industry professionals, and university faculty</span></li>*/}
            {/*            <li><span>Build connections with mentors and judges who are experts in their fields</span></li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*    <div className="info-box" id="compete">*/}
            {/*        <h4>*/}
            {/*            Compete*/}
            {/*        </h4>*/}
            {/*        <ul>*/}
            {/*            <li><span>Top 15 teams advance to the second round of judging</span></li>*/}
            {/*            <li><span>Showcase 3D-printed final prototypes to a panel of judges</span></li>*/}
            {/*            <li><span>Prize money awarded to the top 5 teams</span></li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className="about">*/}
            {/*    <div>*/}
            {/*        <h1> Join Us! </h1>*/}
            {/*        <p>*/}
            {/*            /!*When & Where <br/>/*!/*/}
            {/*            Event will be located at <br/> the University of Oklahoma in Norman, Oklahoma*/}
            {/*        </p>*/}

            {/*    </div>*/}
            {/*    <div>*/}
            {/*        A map will be here soon!*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*</body>*/}
        </>
    );
};
export default Home;
