import { Link, useMatch, useResolvedPath} from "react-router-dom";
import CustomLink from "./customlink";

const Navbar = () => {
    return (
        <nav className="nav">
            <Link to="/" className="site-name">
                {/*MDM*/}
                <img src="/mdmicon.png" alt="MDM" className="logo-image" />
                {/*<link rel="icon" href="%PUBLIC_URL%/mdmicon.png" />*/}
            </Link>
            <ul>
                <CustomLink to="/register">Registration</CustomLink>
                <CustomLink to="/sponsor">Sponsorship</CustomLink>
                {/*<CustomLink to="/history">Past Competitions</CustomLink>*/}
                <CustomLink to="/team">Meet Our Team</CustomLink>
                {/*<CustomLink to="/explore">Explore</CustomLink>*/}
                <CustomLink to="/contact">Contact Us</CustomLink>

            </ul>
        </nav>
    )
}
export default Navbar;
